@use "sass:meta" as ---lhe4q5ck6z;$knowely-themes: (
  $knowely-dark-mode-theme,
  $knowely-light-mode-theme
);

$dark-themes: (
  $knowely-dark-mode-theme,
  $dark-mode-theme
);

$light-themes: (
  $knowely-light-mode-theme,
  $light-mode-theme
);

@mixin withTheme($theme) {
  @if $theme == $dark-mode-theme {
    :root#{$dark-mode-selector} & {
      @content;
    }
  }
  @else if $theme == $light-mode-theme {
    :root#{$light-mode-selector} &,
    :root:not([#{$theme-attribute}]) & {
      @content;
    }
  }
  @else if $theme == $knowely-light-mode-theme {
    :root#{$knowely-light-mode-selector} & {
      @content;
    }
  }
  @else if $theme == $knowely-dark-mode-theme {
    :root#{$knowely-dark-mode-selector} & {
      @content;
    }
  }
  @else {
    @error "Invalid theme passed to 'withTheme' mixin: #{$theme}";
  }
}

@mixin knowely-styles {
  @each $theme in $knowely-themes {
    @include withTheme($theme) {
      @content;
    }
  }
}

@mixin dark-mode-styles {
  @each $theme in $dark-themes {
    @include withTheme($theme) {
      @content;
    }
  }
}

@mixin light-mode-styles {
  @each $theme in $light-themes {
    @include withTheme($theme) {
      @content;
    }
  }
}

;@include ---lhe4q5ck6z.load-css("sass-embedded-legacy-load-done:123214");